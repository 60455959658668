import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import GetApp from '@mui/icons-material/GetApp'; // 아이콘 import
import ListAltIcon from '@mui/icons-material/ListAlt'; // Icon for list view
import MapIcon from '@mui/icons-material/Map'; // Icon for map view
import Search from '@mui/icons-material/Search'; // Importing Search icon

const TitleBar = ({ onModeChange }) => {
  const [viewMode, setViewMode] = useState('map');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleModeChange = (event) => {
    setViewMode(event.target.checked ? 'list' : 'map');
    onModeChange(event.target.checked ? 'list' : 'map');
  };

  const handleSearchClick = () => {
    onModeChange(viewMode+'search');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 0px',
      backgroundColor: '#ffffff',
      color: 'black',
    },
    leftItems: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      marginRight: '15px',
      width: '128px',
      height: '39px',
    },
    title: {
      fontSize: '24px',
    },
    linkButton: {
      marginRight: '10px',
      padding: '5px 5px',
      fontSize: '10px',
      background: '#007BFF',
      color: 'white',
      textDecoration: 'none',
      borderRadius: '5px',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: '20px'
    },
    rightItems: {
      display: 'flex',
      alignItems: 'center',
    },
    iconStyle: {
      marginLeft: '0px',
      fontSize:'12px',
      verticalAlign: 'middle'
    },
    searchStyle: {
      marginLeft: '0px',
      marginRight: '5px',
      fontSize:'12px',
      verticalAlign: 'middle'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftItems}>
        <img src="/logo.png" alt="Logo" style={styles.logo} />
        <FormControlLabel
          control={<Switch checked={viewMode === 'list'} onChange={handleModeChange} />}
          label={viewMode === 'list' ? <MapIcon />:<ListAltIcon /> }
        />
      </div>
      <div style={styles.rightItems}>
          <a href="https://drive.google.com/file/d/1TxHAGQ6Yr1ke208JtcHKTNtdooS9fba4/view?usp=sharing" style={styles.linkButton} target="_blank">
            광고료<GetApp style={styles.iconStyle} />
          </a>
          <a href="https://drive.google.com/file/d/17OGb3aNCRzgSYAYb0-pZ40Y7nveZmaGB/view?usp=sharing" style={styles.linkButton} target="_blank">제안서<GetApp style={styles.iconStyle} /></a>
          <Search style={styles.searchStyle} onClick={handleSearchClick}/>
        </div>
      {/* {!isMobile && (
        <div style={styles.rightItems}>
          <a href="https://drive.google.com/file/d/1W55b6dT15Jd9OzIZZjc2IbOJ_p7-kYBF/view?usp=drive_link" style={styles.linkButton} target="_blank">
            광고료<GetApp style={styles.iconStyle} />
          </a>
          <a href="https://drive.google.com/file/d/17OGb3aNCRzgSYAYb0-pZ40Y7nveZmaGB/view?usp=sharing" style={styles.linkButton} target="_blank">제안서<GetApp style={styles.iconStyle} /></a>
        </div>
      )} */}
      {/* {isMobile && (
        <div>
          <a href="https://drive.google.com/file/d/1W55b6dT15Jd9OzIZZjc2IbOJ_p7-kYBF/view?usp=drive_link" style={styles.linkButton} target="_blank">
            광고료<GetApp style={styles.iconStyle} />
          </a>
          <a href="https://drive.google.com/file/d/17OGb3aNCRzgSYAYb0-pZ40Y7nveZmaGB/view?usp=sharing" style={styles.linkButton} target="_blank">제안서<GetApp style={styles.iconStyle} /></a>
        </div>
      )} */}
    </div>
  );
};

export default TitleBar;
