import React, { useState, useEffect, useRef } from 'react';
import { Select, MenuItem, InputLabel, Drawer, Box, Typography, IconButton, List, ListItem, ListItemText, Card, CardContent, CardMedia, useTheme, useMediaQuery, Button, TextField, FormControl } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const BackOffice = ({ mode }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' 또는 원하는 브레이크포인트

    const [ads, setAds] = useState([
    ]);

    const [exits, setExits] = useState([
    ]);

    const selectedExit = useRef(null);
    const [exitName, setExitName] = useState('');

    const [stations, setStations] = useState([
    ]);
    const [selectedStationId, setSelectedStationId] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://localhost:8001/api/station_exits');
                const data = await response.json();
                console.log(data);
                setExits(data);
            } catch (error) {
            }
        };

        fetchData();

        const fetchStations = async () => {
            try {
                const response = await fetch('http://localhost:8001/api/stations');
                const data = await response.json();
                console.log(data);
                setStations(data);
            } catch (error) {
            }
        };

        fetchStations();
    }, []);


    useEffect(() => {



        if (mode === 'map') {
            window.kakao.maps.load(() => {
                const container = document.getElementById('map');
                const options = {
                    center: new window.kakao.maps.LatLng(37.480819988444736, 126.88367185749627),
                    level: 3,
                };
                const map = new window.kakao.maps.Map(container, options);

                let customOverlay;

                const hideCustomOverlay = () => {
                    if (customOverlay) {
                        customOverlay.setMap(null); // 오버레이 숨김
                    }
                };
                const queryParams = new URLSearchParams(window.location.search);
                const exitId = queryParams.get('exit_id');

                // console.log(stations);
                exits.forEach(station => {
                    const markerPosition = new window.kakao.maps.LatLng(station.latitude, station.longitude);
                    const marker = new window.kakao.maps.Marker({
                        position: markerPosition,
                    });
                    marker.setMap(map);

                    function showBubble() {
                        var content = `
                    <div style="position: relative; padding: 10px; width: 350px; text-align: center; background-color: white; border-radius: 8px; border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0,0,0,0.2);">
                        <button id="closeButton" style="color: white; position: absolute; top: 0px; right: 5px; background: none; border: none; font-size: 30px; cursor: pointer;">&times;</button>
                        <iframe width="100%" height="157" src="https://www.youtube.com/embed/${station.ad.video_url}" frameborder="0" allowfullscreen></iframe>
                        <hr style="margin-top: 10px; border: 0; height: 1px; background-color: #ccc;">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px;">
                            <strong>${station.station.name} (${station.exit_name})</strong>
                            <button id="openDrawerButton" class="btn btn-primary">광고리스트 보기</button>
                        </div>
                        <div style="position: absolute; left: 55%; bottom: -22px; transform: translateX(-50%) skewX(-60deg); width: 0; height: 0; border-left: 20px solid transparent; border-right: 15px solid transparent; border-top: 20px solid rgba(0,0,0,0.2);"></div>
                        <div style="position: absolute; left: 55%; bottom: -20px; transform: translateX(-50%) skewX(-60deg); width: 0; height: 0; border-left: 20px solid transparent; border-right: 15px solid transparent; border-top: 20px solid white;"></div>
                    </div>
                    `;
                        if (customOverlay) {
                            customOverlay.setMap(null); // 오버레이 숨김
                        }

                        // 커스텀 오버레이 생성하지만, 초기에는 지도에 표시하지 않음
                        customOverlay = new window.kakao.maps.CustomOverlay({
                            position: markerPosition,
                            content: content, // 여기서 content는 이전 예제의 HTML 문자열
                            xAnchor: 0.5,
                            yAnchor: 1.24,
                            zIndex: 3
                        });

                        customOverlay.setMap(map);

                        const button = document.querySelector('#openDrawerButton');
                        button.addEventListener('click', (event) => {
                            event.stopPropagation();
                            handleListItemClick(station);
                        });

                        // 오버레이의 닫기 버튼에 이벤트 리스너 추가
                        document.querySelector('#closeButton').addEventListener('click', () => {
                            customOverlay.setMap(null);
                        });
                    }

                    if (station.id.toString() === exitId) {
                        showBubble();
                    }

                    // // 마커 클릭 이벤트 리스너 내부에서 전역 함수 호출
                    // window.kakao.maps.event.addListener(marker, 'click', () => {
                    //     const queryParams = new URLSearchParams(window.location.search);
                    //     queryParams.set('exit_id', station.id);
                    //     // console.log(queryParams);
                    //     window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);

                    //     // 커스텀 오버레이에 표시될 내용, '광고리스트 보기' 버튼 onclick에 전역 함수 호출 설정
                    //     showBubble();


                    // });
                });
                window.kakao.maps.event.addListener(map, 'click', function(mouseEvent) {
                    // 클릭한 위치의 위도와 경도 정보를 가져옵니다
                    const latlng = mouseEvent.latLng;
                    const lat = latlng.getLat(); // 위도
                    const lng = latlng.getLng(); // 경도

                    // 콘솔에 위도와 경도 출력
                    alert(`Clicked longitude: ${lng},${lat}`);
                });
                // 지도 클릭 시 오버레이 숨김
                // window.kakao.maps.event.addListener(map, 'click', hideCustomOverlay);
                // window.kakao.maps.event.addListener(map, 'dragstart', hideCustomOverlay);
            });

        }
    }, [mode, exits]);


    const handleListItemClick = (station) => {
        console.log(station);
        selectedExit.current = station;
        setExitName(selectedExit.current.exit_name);
        setSelectedStationId(selectedExit.current.station.id);

        const fetchData = async () => {
            try {
                const response = await fetch(`http://localhost:8001/api/ads?exit_id=${station.id}`);
                const data = await response.json();
                console.log(data);
                setAds(data);
            } catch (error) {
            }
        };

        fetchData();
        setDrawerOpen(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('http://localhost:8001/api/updateStationExit', {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: selectedExit.current.id,
                    stationId: selectedStationId,
                    exitName: exitName
                })
            });
            const data = await response.text();
            alert("저장되었습니다.");
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while updating');
        }
    };

    return (
        <>
            {mode === 'map' && <div id="map" style={{ width: '100%', height: `calc(100vh - 70px)` }}></div>}
            {mode === 'list' && <List component="nav">
                {exits.map((station, index) => (
                    <ListItem button key={index} onClick={() => handleListItemClick(station)}>
                        <ListItemText primary={`${station.station.name} (${station.exit_name})`} />
                    </ListItem>
                ))}
            </List>}
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={() => { selectedExit.current = null; setDrawerOpen(false); }}
                sx={{
                    width: isMobile ? '100%' : '30%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? '100%' : '30%',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box
                    role="presentation"
                    sx={{ width: '100%', height: '100%', overflowY: 'auto' }}
                >
                    {isMobile && (
                        <IconButton onClick={() => setDrawerOpen(false)} sx={{ m: 1 }}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}

                    <FormControl onSubmit={handleSubmit} component="form" sx={{ width: '94%', m: 2 }}>
                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                            저장하기
                        </Button>
                        <TextField
                            label="출구 이름"
                            variant="outlined"
                            fullWidth
                            value={exitName}
                            onChange={(e) => setExitName(e.target.value)}
                            sx={{ mb: 2, mt: 2 }}
                        />
                        <Select
                            labelId="station-select-label"
                            value={selectedStationId}
                            label="역 선택"
                            onChange={(e)=>setSelectedStationId(e.target.value)}
                            fullWidth
                        >
                            {stations.map((station) => (
                                <MenuItem key={station.id} value={station.id}>
                                    {station.name}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                    {ads.map((ad, index) => (
                        <Card key={index} sx={{ m: 2 }}>
                            <CardMedia
                                component="iframe"
                                height="300px"
                                src={`https://www.youtube.com/embed/${ad.video_url}`}
                                title={`YouTube video player ${index}`}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {ad.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Drawer>
        </>
    );
};

export default BackOffice;
