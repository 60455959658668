import React from 'react';
import './footer.css';  // 스타일 파일을 임포트합니다.

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>상호: 주식회사 더피플 대표: 이승희 사업자번호: 786-81-02402 주소: 서울 금천구 디지털로9길 65, 1205호 전화번호: 02-2038-4364 이메일:wangpory@gmail.com</p>
      </div>
    </footer>
  );
}

export default Footer;