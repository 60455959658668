import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Box, Typography, IconButton, List, ListItem, ListItemText, Card, CardContent, CardMedia, useTheme, useMediaQuery,TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Main = ({ mode }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' 또는 원하는 브레이크포인트

    const [ads, setAds] = useState([
    ]);

    const [exits, setExits] = useState([
    ]);
    const [searchTerm, setSearchTerm] = useState("");  // State to store search term

    const map = useRef(null);
    const customOverlay = useRef(null);

    const selectedExit = useRef(null);
    const centerChangeTimeout = useRef(null);
    const lastSearchTime = useRef(Date.now());
    const closestMarkerRef = useRef(null);

    useEffect(() => {
        console.log("fetch");
        const fetchData = async () => {
            try {
                const response = await fetch('https://nineboxlinux.cafe24.com/api/stationexits/');
                const data = await response.json();
                console.log(data);
                setExits(data);
            } catch (error) {
            }
        };

        fetchData();


    }, []);


    useEffect(() => {
        console.log("map");


        if (mode === 'map') {
            window.kakao.maps.load(() => {
                console.log('load');
                const container = document.getElementById('map');
                const options = {
                    center: new window.kakao.maps.LatLng(37.480819988444736, 126.88367185749627),
                    level: 7,
                };
                map.current = new window.kakao.maps.Map(container, options);

                
                const hideCustomOverlay = () => {
                    if (customOverlay.current) {
                        customOverlay.current.setMap(null); // 오버레이 숨김
                    }
                };
                const queryParams = new URLSearchParams(window.location.search);
                const exitId = queryParams.get('exit_id');
                console.log(exitId);
                if(!exitId){
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            const userLat = position.coords.latitude;
                            const userLng = position.coords.longitude;
                            const userLatLng = new window.kakao.maps.LatLng(userLat, userLng);
                            let closestMarker = null;
                            let minDist = Infinity;
            
                            exits.forEach(station => {
                                const markerPosition = new window.kakao.maps.LatLng(station.latitude, station.longitude);
                                const dist = getDistance(userLatLng, markerPosition);
                                if (dist < minDist) {
                                    minDist = dist;
                                    closestMarker = station;
                                }
                            });
            
                            if (closestMarker && closestMarkerRef.current!=closestMarker) {
                                closestMarkerRef.current=closestMarker;
                                const closestMarkerPosition = new window.kakao.maps.LatLng(closestMarker.latitude, closestMarker.longitude);
                                map.current.setCenter(closestMarkerPosition);
                                showBubble(closestMarker);
                                console.log('Closest Marker:', closestMarker);
                            }
                        }, (error) => {
                            console.error('Geolocation error:', error);
                            const samsungStationLatLng = new window.kakao.maps.LatLng(37.50881844624927, 127.06298806746055); // 삼성역 위도 및 경도
                            map.current.setCenter(samsungStationLatLng);
                        });
                    } else {
                        console.error('Geolocation is not supported by this browser.');
                        const samsungStationLatLng = new window.kakao.maps.LatLng(37.50881844624927, 127.06298806746055); // 삼성역 위도 및 경도
                        map.current.setCenter(samsungStationLatLng);
                    }
    
                }

                // console.log(stations);
                exits.forEach(station => {
                    const markerPosition = new window.kakao.maps.LatLng(station.latitude, station.longitude);
                    const marker = new window.kakao.maps.Marker({
                        position: markerPosition,
                    });
                    marker.setMap(map.current);



                    if (station.id.toString() === exitId) {
                        const closestMarkerPosition = new window.kakao.maps.LatLng(station.latitude, station.longitude);
                        map.current.setCenter(closestMarkerPosition);
                        showBubble(station);
                    }

                    // 마커 클릭 이벤트 리스너 내부에서 전역 함수 호출
                    window.kakao.maps.event.addListener(marker, 'click', () => {


                        // 커스텀 오버레이에 표시될 내용, '광고리스트 보기' 버튼 onclick에 전역 함수 호출 설정
                        showBubble(station);

                        
                    });
                    
                });
                window.kakao.maps.event.addListener(map.current, 'center_changed', () => {
                    const now = Date.now();
                    const minInterval = 100; // 최소 1초 간격으로 검색

                    if (now - lastSearchTime.current >= minInterval) {
                        const center = map.current.getCenter();
                        console.log(center);
                        let closestMarker = null;
                        let minDist = Infinity;

                        exits.forEach(station => {
                            const markerPosition = new window.kakao.maps.LatLng(station.latitude, station.longitude);
                            const dist = getDistance(center, markerPosition);
                            if (dist < minDist) {
                                minDist = dist;
                                closestMarker = station;
                            }
                        });
        
                        if (closestMarker && closestMarkerRef.current!=closestMarker) {
                            closestMarkerRef.current=closestMarker;
                            const closestMarkerPosition = new window.kakao.maps.LatLng(closestMarker.latitude, closestMarker.longitude);
                            // map.current.setCenter(closestMarkerPosition);
                            showBubble(closestMarker);
                            console.log('Closest Marker:', closestMarker);
                        }

                        lastSearchTime.current=Date.now();
                    }
                });

            });

        }
    }, [mode, exits]);

    function showBubble(station) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('exit_id', station.id);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);

        var content = `
        <div style="position: relative; padding: 3px; width: 350px; text-align: center; background-color: white; border-radius: 8px; border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0,0,0,0.2);">
            <button id="closeButton" style="color: white; position: absolute; top: 0px; right: 5px; background: none; border: none; font-size: 30px; cursor: pointer;">&times;</button>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/${station.ad?.video_url}?autoplay=1&mute=1${isMobile ? '&fs=0' : ''}" allow="autoplay; encrypted-media" frameborder="0" allowfullscreen></iframe>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 0px;">
                <strong style="font-size:14px">${station.station.name}역 (${station.name})</strong>
                <div style="display: flex; justify-content: space-between; align-items: center;"> 
                    <a href="https://drive.google.com/file/d/1W55b6dT15Jd9OzIZZjc2IbOJ_p7-kYBF/view?usp=drive_link" target="_blank" style="text-decoration: none; margin-right: 5px;">
                        <button class="btn btn-primary" style="font-size: 10px;">
                            광고료
                        </button>
                    </a>
                    <button id="openDrawerButton" class="btn btn-primary" style="font-size: 10px;">P/L</button>
                </div>
            </div>
            <div style="position: absolute; left: 55%; bottom: -22px; transform: translateX(-50%) skewX(-60deg); width: 0; height: 0; border-left: 20px solid transparent; border-right: 15px solid transparent; border-top: 20px solid rgba(0,0,0,0.2);"></div>
            <div style="position: absolute; left: 55%; bottom: -20px; transform: translateX(-50%) skewX(-60deg); width: 0; height: 0; border-left: 20px solid transparent; border-right: 15px solid transparent; border-top: 20px solid white;"></div>
        </div>
        `;
        if (customOverlay.current) {
            customOverlay.current.setMap(null); // 오버레이 숨김
        }

        // 커스텀 오버레이 생성하지만, 초기에는 지도에 표시하지 않음
        customOverlay.current = new window.kakao.maps.CustomOverlay({
            position: new window.kakao.maps.LatLng(station.latitude, station.longitude),
            content: content, // 여기서 content는 이전 예제의 HTML 문자열
            xAnchor: 0.5,
            yAnchor: 1.24,
            zIndex: 3
        });

        customOverlay.current.setMap(map.current);

        const button = document.querySelector('#openDrawerButton');
        if(button){
            button.addEventListener('click', (event) => {
                event.stopPropagation();
                handleListItemClick(station);
            });
        }


        // 오버레이의 닫기 버튼에 이벤트 리스너 추가
        const closeButton =document.querySelector('#closeButton');
        if(closeButton){
            closeButton.addEventListener('click', () => {
                customOverlay.current.setMap(null);
            });
        }

    }

    function getDistance(latlngA, latlngB) {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = degreesToRadians(latlngB.getLat() - latlngA.getLat());
        const dLong = degreesToRadians(latlngB.getLng() - latlngA.getLng());
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degreesToRadians(latlngA.getLat())) * Math.cos(degreesToRadians(latlngB.getLat())) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in meters
    }
    
    // 라디안 변환 함수
    function degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    const handleListItemClick = (station) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('exit_id', station.id);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
        if(mode=='mapsearch'){
            window.location.reload();
        }else{
            selectedExit.current = station;
            const fetchData = async () => {
                try {
                    const response = await fetch(`https://nineboxlinux.cafe24.com/api/ads/?exit=${station.id}`);
                    const data = await response.json();
                    console.log(data);
                    setAds(data);
                } catch (error) {
                }
            };
    
            fetchData();
            setDrawerOpen(true);
        }

    };

    const filteredExits = searchTerm ? exits.filter(exit => 
        (exit.station.name+"역").toLowerCase().includes(searchTerm.toLowerCase()) || 
        exit.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) : exits;

    return (
        <>
            {mode === 'map' && <div id="map" style={{ width: '100%', flex: '1' }}></div>}
            {(mode === 'list' || mode === 'listsearch'|| mode === 'mapsearch') && <>
            {(mode === 'listsearch'||mode==='mapsearch') &&                     <TextField 
                        fullWidth 
                        variant="outlined" 
                        placeholder="검색" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                    />}
            <List component="nav">
                {filteredExits.map((exit, index) => (
                    <ListItem button key={index} onClick={() => handleListItemClick(exit)}>
                        <ListItemText primary={`${exit.station.name}역 (${exit.name})`} />
                    </ListItem>
                ))}
            </List></>}
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={() => {selectedExit.current = null;setDrawerOpen(false);}}
                sx={{
                    width: isMobile ? '100%' : '30%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? '100%' : '30%',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box
                    role="presentation"
                    sx={{ width: '100%', height: '100%', overflowY: 'auto' }}
                >
                    {isMobile && (
                        <IconButton onClick={() => setDrawerOpen(false)} sx={{ m: 1 }}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {ads.map((ad, index) => (
                        <Card key={index} sx={{ m: 2 }}>
                            <CardMedia
                                component="iframe"
                                height="300px"
                                src={`https://www.youtube.com/embed/${ad.video_url}${isMobile ? '?fs=0' : ''}`}
                                title={`YouTube video player ${index}`}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {ad.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Drawer>
        </>
    );
};

export default Main;
