import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Main from './pages/main/main.js'
import TitleBar from './components/titlebar.js'
import BackOffice from './pages/backoffice/backoffice.js';
import Footer from './components/footer.js';

function App() {
  const [mode, setMode] = useState('map');

  useEffect(() => {
    const setViewportHeight = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // 초기 설정과 리사이즈 이벤트에 함수를 바인딩합니다.
    window.addEventListener('resize', setViewportHeight);
    setViewportHeight();

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
        window.removeEventListener('resize', setViewportHeight);
    };
}, []);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<div style={{    display: 'flex', flexDirection: 'column', height:'calc(var(--vh, 1vh) * 100)'}}><TitleBar onModeChange={handleModeChange} />
              <Main mode={mode}>

              </Main><Footer /></div>} />
            <Route path="/bo12345678" element={<><TitleBar onModeChange={handleModeChange} />
              <BackOffice mode={mode}>

              </BackOffice></>} />
          </Routes>
        </div>
      </Router>

    </div>
  );
}

export default App;
